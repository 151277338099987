import { useTranslation } from 'next-i18next';
import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, DropDown, FilterButton } from '@components/search/common/common';
import { IButtonProps } from '@components/search/common/common.types';
import { CustomText, Skeleton } from '@components/ui';
import { IExtensionsFilterProps } from '@components/search/filters/ExtensionsFilter/ExtensionsFilter.types';
import { ErrorTextWrapper, FilterWrapper } from '@components/search/common/common.styles';
import useDropdown from '@hooks/useDropdown';
import { CheckboxRowWrapper } from './ExtensionsFilter.styles';
import { buttonGap, buttonHeight, getDropdownProps } from './ExtensionsFilter.constants';

const ExtensionsFilter = (props: IExtensionsFilterProps) => {
  const { buttonRef, dropdownRef, isDropdownOpen, handleButtonClick } = useDropdown();
  const [dropdownProps, setDropdownProps] = useState(null);
  const { t } = useTranslation(['search']);

  const { loading, error, data } = props;
  const extensions = data?.getExtensionConfigs || [];

  const slicedExtensions: { __typename?: 'ExtensionConfig'; id: number; name: string }[][] = [];
  let tmp: { __typename?: 'ExtensionConfig'; id: number; name: string }[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < extensions.length; i++) {
    const extension = extensions[i];
    tmp.push(extension);
    if (i % 3 === 2) {
      slicedExtensions.push(tmp);
      tmp = [];
    }
  }
  const dropdownContent = error ? (
    <ErrorTextWrapper>
      <CustomText size={14} lineHeight={'20px'} color={'#313135'}>
        {t('search:filters.extensionsLoadErrorText')}
      </CustomText>
      <CustomText weight={600} size={14} lineHeight={'20px'} color={'#313135'}>
        {t('search:filters.refreshInstruction')}
      </CustomText>
    </ErrorTextWrapper>
  ) : (
    slicedExtensions.map((extensions, row) => (
      <Fragment key={row}>
        {extensions.map((extension) =>
          loading ? (
            <Skeleton height={'24px'} width={'124px'} key={extension.id} />
          ) : (
            <Checkbox key={extension.id} onChange={props.onChange} labelText={extension.name} isActive={props.selectedExtensions.includes(extension.name.slice(1))} />
          ),
        )}
      </Fragment>
    ))
  );

  const filterButtonProps: IButtonProps = {
    height: buttonHeight,
    isActive: (props.selectedExtensions && props.selectedExtensions.length > 0) || isDropdownOpen,
    onClick: handleButtonClick,
  };

  useEffect(() => {
    if (buttonRef.current) {
      const { x, y, width } = buttonRef.current.getBoundingClientRect();
      const positionX = window.innerWidth / 2 > x + width ? x : 16;
      const positionY = y + buttonHeight + buttonGap;
      setDropdownProps(getDropdownProps(positionX, positionY, error));
    }
  }, [isDropdownOpen]);

  return (
    <FilterWrapper>
      <FilterButton ref={buttonRef} {...filterButtonProps}>
        {t('search:filters.formats')}
      </FilterButton>
      {isDropdownOpen && (
        <DropDown ref={dropdownRef} {...dropdownProps}>
          {dropdownContent}
        </DropDown>
      )}
    </FilterWrapper>
  );
};

export default ExtensionsFilter;
