import { useTranslation } from 'next-i18next';
import React from 'react';
import { SortButtonBase, SortDropdownWrapper, SortOrderButton, SortWrapper } from '@components/search/Sort/Sort.styles';
import { VShapedIcon } from '@components/search/icons';
import { CustomText } from '@components/ui';
import { IButtonBaseProps, IButtonProps, IDropdownWrapperProps } from '@components/search/common/common.types';
import { SORT_ORDERS } from '@components/search/Sort/Sort.constants';
import { ISortDropDownProps, ISortProps } from '@components/search/Sort/Sort.types';
import useDropdown from '@hooks/useDropdown';
import { SORT_CODE } from '@components/filter/constants';
import { PAGE_NAME } from '../../../src/constants';

const SortButton = React.forwardRef((props: IButtonProps, ref: React.MutableRefObject<HTMLButtonElement>) => {
  const sortButtonBaseProps: IButtonBaseProps = {
    height: props.height,
    isActive: props.isActive,
    onClick: props.onClick,
  };
  return (
    <SortButtonBase {...sortButtonBaseProps} ref={ref}>
      <CustomText weight={600} lineHeight={'20px'} color={'#313135'}>
        {props.children}
      </CustomText>
      <VShapedIcon />
    </SortButtonBase>
  );
});

const SortDropDown = React.forwardRef((props: ISortDropDownProps, ref: React.MutableRefObject<HTMLDivElement>) => {
  const { t } = useTranslation(['search']);

  // 추천순은 드롭다운에 포함되지 않음.
  const filterSortOrders = SORT_ORDERS.filter((sort) => sort.key !== SORT_CODE.RECOMMENDED);
  const sortOrders = props.pageType === PAGE_NAME.SEARCH ? filterSortOrders : filterSortOrders.filter((sortOrder) => sortOrder.key !== SORT_CODE.ACCURACY);
  const dropdownContent = sortOrders.map((sortOrder) => (
    <SortOrderButton
      key={sortOrder.key}
      isActive={props.selectedSortOption === sortOrder.key}
      onClick={() => {
        props.sortOptionSelector(sortOrder.key);
      }}
    >
      <CustomText weight={props.selectedSortOption === sortOrder.key ? 600 : 400} lineHeight={'20px'} color={'#313135'} style={{ whiteSpace: 'pre' }}>
        {t(sortOrder.value)}
      </CustomText>
    </SortOrderButton>
  ));

  const dropdownWrapperProps: IDropdownWrapperProps = {
    height: props.dropdownHeight,
    width: props.dropdownWidth,
    offsetTop: props.offsetTop,
    isFixed: false,
  };

  return (
    <SortDropdownWrapper {...dropdownWrapperProps} ref={ref}>
      {dropdownContent}
    </SortDropdownWrapper>
  );
});

const Sort = (props: ISortProps) => {
  const { buttonRef, dropdownRef, isDropdownOpen, handleButtonClick, handleDropdownClose } = useDropdown();

  const { t } = useTranslation(['search']);

  const handleSortOptionClick = (option: string) => {
    props.onSelectSortOrder(option);
    handleDropdownClose();
  };

  const buttonHeight = 36;
  const sortButtonProps = {
    height: buttonHeight,
    isActive: isDropdownOpen,
    onClick: handleButtonClick,
  };

  const sortDropdownProps: ISortDropDownProps = {
    dropdownHeight: props.pageType === PAGE_NAME.SEARCH ? '160px' : '128px',
    offsetTop: buttonHeight,
    dropdownWidth: 'auto',
    selectedSortOption: props.sort,
    sortOptionSelector: handleSortOptionClick,
    pageType: props.pageType,
  };

  return (
    <SortWrapper>
      <SortButton {...sortButtonProps} ref={buttonRef}>
        {t(`search:sortOrders.${props.sort}`)}
      </SortButton>
      {isDropdownOpen && <SortDropDown {...sortDropdownProps} ref={dropdownRef} />}
    </SortWrapper>
  );
};

export default Sort;
