import styled from 'styled-components';

export const ChipsWrapper = styled.div`
  width: 100%;
  display: flex;
  .chips-wrapper {
    position: relative;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .button-wrapper {
    margin-left: auto;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
